import { useEffect } from 'react';
import './App.css';
import { HomeScene } from './scenes';

function App() {

  useEffect(() => {
    const randomYPosition = Math.floor(Math.random() * Math.abs(2160 - window.innerHeight));
    document.body.style.backgroundPosition = `0 -${randomYPosition}px`;
  }, []);

  return (
    <HomeScene />
  );
}

export default App;
