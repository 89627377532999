import { useState } from 'react';
import { Character } from '../components';
// import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const HomeScene = () => {

    const guiJson = { id: 1, name: "home", path: "/", type: "blocks", 
      // contents: '[{"id":1,"icon":"who-94","title":"Jimmy","link":"/jimmy","items":[]},{"id":2,"icon":"who-92","title":"Victoria","link":"/victoria","items":[]},{"id":3,"icon":"who-26","title":"Dongdong","link":"/dongdong","items":[]},{"id":4,"icon":"who-53","title":"Tangtang","link":"/tangtang","items":[]},{"id":5,"icon":"","title":"Downloader","link":"/downloader","items":[]},{"id":6,"icon":"","title":"Nextcloud","link":"/nextcloud","items":[]},{"id":7,"icon":"","title":"OwnCloud","link":"/owncloud","items":[]},{"id":8,"icon":"","title":"Seafile","link":"/seafile","items":[]},{"id":9,"icon":"","title":"Syncthing","link":"/syncthing","items":[]},{"id":10,"icon":"","title":"Jellyfin","link":"/jellyfin","items":[]},{"id":11,"icon":"","title":"Photoprism","link":"/photoprism","items":[]},{"id":12,"icon":"","title":"Server stats","link":"/stats","items":[]},{"id":13,"icon":"","title":"Test","link":"/test","items":[]},{"id":14,"icon":"","title":"Todo/Shop","link":"/app1","items":[]}]'};
      contents: '[{"id":1,"icon":"who-94","title":"Jimmy","link":"","items":[]},{"id":2,"icon":"who-92","title":"Victoria","link":"","items":[]},{"id":3,"icon":"who-26","title":"Dongdong","link":"","items":[]},{"id":4,"icon":"who-53","title":"Tangtang","link":"","items":[]}]'};

    const [gui, ] = useState(guiJson);

    // useEffect(() => {
    
    // }, []);

    if(!gui || !gui.contents) return <>Loading {baseUrl}</>;

    return (
        <div className="Main-container">

          <div className="group-container moving-group">
            {JSON.parse(gui.contents).map((content, idx) =>
              <Character key={idx} id={idx+1} content={content} index={idx}/>
            )}
          </div>

          <iframe className="status-container" title="status" src="https://ho.ming.nz/" ></iframe>

        </div>
    );
}

export default HomeScene;