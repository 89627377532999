import { useState } from 'react';
import '../images/ff-characters.css';

const Character = ({id, content, index=1}) => {

    const config = content;//CONFIG.find(item => item.id === id);
    
    const [isJumping, setIsJumping] = useState(false);

    const handleMouseEnter = () => {
      setIsJumping(true);
    };
  
    const handleMouseLeave = () => {
      setIsJumping(false);
    };

    let fallbackIconId = id+(new Date()).getDate();
    while(fallbackIconId>99) fallbackIconId -= 99;
    const fallbackIcon = `who-${(fallbackIconId).toString().padStart(2, '0')}`;

    const iconId = config.icon || fallbackIcon;

    // style={{ animationDelay: isJumping ? '0s' : `${id * 0.2}s` }}

    return (
        <div className={`ff-character ${isJumping ? 'jump' : ''} icon${index}`} id={iconId}
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} title={config.title}></div>
    );
}

export default Character;